<template>
  <div class="container_body">
    <header>
      <span>江西捷点科技有限公司</span>
    </header>
    <div class="map">
      <baidu-map class="bmView" :scroll-wheel-zoom="true" :center="center" :zoom="zoom" @ready="handler">
      </baidu-map>
    </div>
    <div class="row" :class="flag?'mini':''">
      <i class="el-icon-menu" v-show="flag" @click="flag = false"></i>
      <div class="top_img" v-show="!flag">
        <div class="title">
          <div>告警列表</div>
          <div>
            <el-button type="text" size="default" icon="el-icon-remove" @click="minimize"></el-button>
          </div>
        </div>
      </div>
      <div class="content_box" v-show="!flag">
        <div class="content">
          <el-table :data="alarmList" height="100%" v-show="tableFlag">
            <el-table-column label="单位名" show-overflow-tooltip>
              <template slot-scope="scope">
                {{ scope.row.equipmentObject.unitObject.unitName }}
              </template>
            </el-table-column>
            <el-table-column label="告警设备" show-overflow-tooltip>
              <template slot-scope="scope">
                {{ scope.row.equipmentTypeName }}
              </template>
            </el-table-column>
            <el-table-column label="告警类型" show-overflow-tooltip>
              <template slot-scope="scope">
                {{ scope.row.alarmTypeName }}
              </template>
            </el-table-column>
            <el-table-column label="定位" width="50">
              <template slot-scope="scope">
                <el-tooltip content="定位" placement="right" effect="dark">
                  <div class="el-icon-location" style="cursor: pointer;" @click="showAlarm(scope.row)"> </div>
                </el-tooltip>
              </template>
            </el-table-column>
          </el-table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import _ from 'lodash'
import Vue from 'vue'
export default {
  data () {
    return {
      point: '',
      marker: '',
      label: '',
      setMapStyle: '',
      infoWindow: '',
      markerIcon: '',
      selfMarker: '',
      alarmList: [],
      alarmTotal: 0,
      zoom: 15,
      map: null,
      // 地图初始中心点
      center: {
        lng: 115.0196282584,
        lat: 27.1058914875
      },
      flag: false,
      tableFlag: true,
      timer: null,
      preAlarmCount: 0
    }
  },
  watch: {
    alarmList (newVal, oldVal) {
      this.drawAlarm()
      if (this.alarmList.length > 0) {
        this.showAlarm(this.alarmList[0])
      } else {
        this.map.getOverlays().forEach(item => {
          this.map.removeOverlay(item)
        })
      }
    },
    flag (newVal, oldVal) {
      if (this.flag) {
        this.tableFlag = false
      } else {
        setTimeout(() => {
          this.tableFlag = true
        }, 600)
      }
    }
  },
  mounted () {
    this.timer = setInterval(() => this.getAlarmTotal(), 10000)
  },
  methods: {
    // 地图初始化
    handler ({ BMap, map }) {
      this.map = map
      this.point = (lng, lat) => {
        return new BMap.Point(lng, lat)
      }
      this.infoWindow = (content, opts) => {
        return new BMap.InfoWindow(content, opts)
      }
      this.marker = (obj) => {
        return new BMap.Marker(obj)
      }
      this.label = (name, num1, num2) => {
        return new BMap.Label(name, { offset: new BMap.Size(num1, num2) })
      }
      this.markerIcon = (imgUrl, num1, num2) => {
        return new BMap.Icon(imgUrl, new BMap.Size(num1, num2))
      }
      this.selfMarker = (pt, myIcon) => {
        return new BMap.Marker(pt, { icon: myIcon })
      }
      // 地图的初始中心点
      const point = this.point(this.center.lng, this.center.lat)
      this.map.centerAndZoom(point, 15)
      // 初始化地图后请求告警
      this.getAlarmTotal()
    },
    // 轮询获取告警信息
    async getAlarmTotal () {
      const { data: result } = await this.$axios.get('/alarm/getCurrentAlarmForZg119', { params: { pageNo: 1, pageSize: 100, areaCode: this.areaCode } })
      if (result.code === 200) {
        this.alarmTotal = result.data.total
        if (!_.isEqual(this.alarmList, result.data.result)) {
          this.alarmList = result.data.result
        }
        if (result.data.total === 0) {
          this.drawer = false
        }
      } else {
        this.$message.error(result.msg)
      }
    },
    // 轮询画告警点
    drawAlarm () {
      //  遍历所有的告警信息
      if (this.alarmList.length > 0) {
        this.alarmList.forEach((item, index) => {
          const point = this.point(item.equipmentObject.lng, item.equipmentObject.lat)
          const myIcon = this.markerIcon(require('../../assets/img/' + 'alarm' + '.gif'), 50, 50)
          const marker = this.selfMarker(point, myIcon)
          marker.type = 'alarm'
          marker.addEventListener('click', (e) => {
            this.showAlarm(item)
          })
          this.map.addOverlay(marker)
        })
      } else {
        //  遍历所有的告警信息
        if (this.map.getOverlays()) {
          this.map.getOverlays().forEach(item => {
            if (item.type === 'alarm') {
              this.map.removeOverlay(item)
              return false
            }
          })
        }
      }
    },
    // 告警的消息框
    async showAlarm (item) {
      const device = item.equipmentObject
      const point = this.point(device.lng, device.lat)
      this.map.centerAndZoom(point, 16)
      const timeFormat = Vue.filter('timeFormat')
      const date = timeFormat(item.alarmDate)
      let img = ''
      const imgUrl = require('../../assets/img/failed.png')
      if (item.equipmentObject.unitObject.unitImage) {
        img = `<img src="${item.equipmentObject.unitObject.unitImage}" width="100%" height="100%" alt="单位图片">`
      } else {
        img = `<img src="${imgUrl}" width="100%" height="100%" alt="暂无图片">`
      }
      const content = `
         <div class="alarmInfoBox" style="font-size:14px;display: flex;flex-direction: column;justify-content: space-between;width:100%;height:100%">
            <div style="font-size:16px;margin-bottom: 5px;padding-bottom: 5px;border-bottom: 1px solid #ccc;">
              <h3>${item.equipmentObject.unitObject.unitName}</h3>
              <p style="color:#333;font-size:12px;">${item.areaName}</p>
            </div>
            <div style="width:100%;display: flex;">
              <div style="width:140px;height:160px;margin-right:6px">` + img + `</div>
              <div>
                <p>设备类型:${item.equipmentTypeName}</p>
                <p>告警类型:${item.alarmTypeName}</p>
                <p>内容:${item.content}</p>
                <p>单位地址:${item.equipmentObject.unitObject.address || '暂无地址'}</p>
                <p>安装地址:${item.equipmentObject.address || '暂无地址'}</p>
                <p>联系人:${item.equipmentObject.unitObject.legalName}</p>
                <p>联系电话:${item.equipmentObject.unitObject.legalPhone || '暂无电话'}</p>
                <p style="color:#F56C6C">告警时间:${date}</p>
              </div>
            </div>
          </div>`

      this.infoWin = this.infoWindow(content, this.opts)
      this.map.openInfoWindow(this.infoWin, point)
    },
    minimize () {
      this.flag = true
    }
  }
}
</script>

<style lang="scss" scoped>
.container_body {
  width: 100vw;
  height: 100vh;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.12), 0 0 6px rgba(0, 0, 0, 0.04);
  overflow: hidden;
  position: relative;
  //background: url("https://xsw-xf.oss-cn-hangzhou.aliyuncs.com/web-static/background.png?versionId=CAEQQBiBgIDOu4aS5hciIGExMWZmMDdiNDEzMzQ1Y2RhNDQyYTg4NTBiMjNkZWI5");
  header {
    height: 70px;
    line-height: 60px;
    background-image: url("../../assets/img/head-bg.png");
    background-repeat: no-repeat;
    background-size: 100% 100%;
    padding: 0 30px;
    box-sizing: border-box;
    > span {
      text-shadow: 4px 6px 6px #14182d;
      font-weight: 700;
      font-size: 30px;
      float: left;
      color: #fff;
    }
  }
  .map {
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    position: relative;
    transition: all 0.5s;
    .bmView {
      width: 100%;
      height: 100%;
      z-index: 1;
    }
  }
  .row {
    width: 30%;
    height: 50%;
    position: absolute;
    left: 4%;
    bottom: 6%;
    background: rgba(3, 22, 52, 0.6);
    border-radius: 20px;
    border-left: 3px solid rgba(3, 22, 52, 0.8);
    border-right: 3px solid rgba(3, 22, 52, 0.8);
    transition: all 0.5s ease-in-out;
    .top_img {
      position: relative;
      .title {
        display: flex;
        align-items: flex-end;
        div:first-child {
          position: absolute;
          left: 20px;
          bottom: 5px;
          color: rgb(3, 54, 73);
          font-weight: bold;
          font-size: 16px;
        }
        div:last-child {
          position: absolute;
          right: 20px;
          bottom: 5px;
          .el-button {
            color: rgb(3, 54, 73);
            padding: 0;
            font-size: 18px;
          }
        }
      }
    }
    .content_box {
      height: 100%;
      position: relative;
      .content {
        height: 100%;
        ::v-deep  .el-table {
          border: none;
          background-color: transparent;
        }
        /* 滚动条 */
        ::-webkit-scrollbar-thumb:horizontal {
          /*水平滚动条的样式*/
          width: 4px;
          background-color: transparent;
        }
        ::-webkit-scrollbar-track-piece {
          background-color: transparent; /*滚动条的背景颜色*/
        }
        ::-webkit-scrollbar {
          width: 10px; /*滚动条的宽度*/
          height: 8px; /*滚动条的高度*/
        }
        ::-webkit-scrollbar-thumb:vertical {
          /*垂直滚动条的样式*/
          height: 50px;
          background-color: rgb(3, 54, 73);
          border-radius: 8px;
        }
        ::-webkit-scrollbar-thumb:hover {
          /*滚动条的hover样式*/
          height: 50px;
          background-color: rgba(3, 54, 73, 0.8);
          border-radius: 8px;
        }
        ::v-deep  .el-table th {
          color: #f2f2f2;
          border-bottom: 1px solid rgba(61, 194, 241, 0.285);
          background-color: transparent;
        }
        ::v-deep  .el-table tr {
          color: #f2f2f2;
          border: none;
          background-color: transparent;
        }
        ::v-deep  .el-table td,
        .el-table th.is-leaf {
          border: none;
        }
        ::v-deep  .el-table tr:hover > td {
          cursor: pointer;
          color: #fff;
          background-color: rgba(61, 194, 241, 0.1);
        }
        .el-table--border::after,
        .el-table--group::after,
        .el-table::before {
          content: "";
          background-color: transparent;
        }
      }
    }
  }
  .mini {
    width: 60px;
    height: 60px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 2px solid rgb(3, 22, 52);
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.12), 0 0 6px rgba(0, 0, 0, 0.04);
    box-sizing: border-box;
    > i {
      font-size: 18px;
      cursor: pointer;
    }
  }
}
</style>
